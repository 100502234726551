import { Button, FilterCheckbox, FilterTag, Flex, HStack, SearchInput, useDisclosure } from 'designsystem';
import { ChangeEvent, FC, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Filter, FilterModal, FiltersDrawer, gtm, useSetQueryFilter } from 'shared';
import alphabetFilterMessages from '../../utils/alphabetFilterMessages';
import { AtoZFilterQueryParams } from '../../utils/searchProgrammeUtils';
import { StyledIconFilters } from './ProgrammeScheduleFilters';
import { AtoZFilterEnum } from '../../gql/api';
import useAlphabetSearch from '../../hooks/useAlphabetSearch';
import showTypeFilterMessages, { ShowTypeMessageKey } from '../../utils/showTypeFilterMessages';
import camelCase from 'lodash.camelcase';

const ProgrammeAlphabetFilters: FC = () => {
    const { locale, formatMessage } = useIntl();
    const {
        checkboxFilters,
        searchQuery,
        allFilters,
        activeFilters,
        totalHits,
        setSearchQuery,
        setQueryParams,
        removeQueryFilters,
        queryParams,
        drawerFilters,
    } = useAlphabetSearch();
    const drawerProps = useDisclosure();
    const modalProps = useDisclosure();
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);
    const onModal = useCallback(
        (filter: Filter) => {
            setSelectedFilter(filter);
            modalProps.onOpen();
        },
        [modalProps]
    );
    const inputRef: Ref<HTMLInputElement> = useRef();
    const setQueryFilter = useSetQueryFilter(AtoZFilterQueryParams);

    const clearFilter = (key: AtoZFilterEnum, value: string[], deleteValue: string) => {
        setQueryParams({
            [key]: value.filter(val => val !== deleteValue),
        });
    };
    useEffect(() => {
        if (!document.location.search) {
            setQueryParams({
                [AtoZFilterEnum.AToZType]: [locale === 'nl' ? 'Publiek' : 'Public'],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isCheckboxChecked = (key: string) => queryParams[AtoZFilterEnum.AToZType]?.includes(key);

    return (
        <>
            <SearchInput
                defaultValue={searchQuery}
                ref={inputRef}
                py={3}
                px={7}
                borderRadius="100px"
                border="1.5px solid"
                placeholder={formatMessage({ defaultMessage: 'Zoeken' })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const searchValue = e.target.value;
                    gtm.event('search', {
                        search_term: searchValue,
                    });
                    inputRef.current.value = searchValue;
                    setSearchQuery(searchValue);
                }}
                onClear={() => {
                    inputRef.current.value = '';
                    setSearchQuery('');
                }}
            />
            <Flex mt={[4, null, null, 6]}>
                <HStack display={['none', null, null, 'flex']} spacing={6}>
                    {checkboxFilters?.amounts
                        ?.sort((a, b) => {
                            // sort by how the order in which they are defined in showTypeFilterMessages
                            const indexA = Object.keys(showTypeFilterMessages).indexOf(camelCase(a.key));
                            const indexB = Object.keys(showTypeFilterMessages).indexOf(camelCase(b.key));
                            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
                        })
                        .map(({ key }) => {
                            const message = showTypeFilterMessages[camelCase(key) as ShowTypeMessageKey];
                            return (
                                <FilterCheckbox
                                    key={key}
                                    value={key}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setQueryFilter(checkboxFilters.filter, key, e.target.checked, 'checkbox');
                                    }}
                                    isChecked={isCheckboxChecked(key)}
                                >
                                    {message ? formatMessage(message) : key}
                                </FilterCheckbox>
                            );
                        })}
                </HStack>

                <Button
                    ml="auto"
                    flexShrink={['unset', null, null, 1]}
                    w={['100%', null, null, 'auto']}
                    rightIcon={<StyledIconFilters />}
                    onClick={() => drawerProps.onOpen()}
                >
                    Filters
                </Button>
            </Flex>
            {activeFilters.length > 0 && (
                <HStack mt={6} flexWrap="wrap" spacing={2}>
                    {activeFilters.map(({ key, value }) => (
                        <>
                            {value.map(val => (
                                <FilterTag key={val} onClear={() => clearFilter(key, value, val)}>
                                    {val.includes('|') ? val.split('|')[1] : val}
                                </FilterTag>
                            ))}
                        </>
                    ))}
                    {activeFilters.length > 0 && (
                        <FilterTag onClear={() => removeQueryFilters()}>
                            <FormattedMessage defaultMessage="Alles wissen" />
                        </FilterTag>
                    )}
                </HStack>
            )}
            {allFilters?.length > 0 && (
                <FiltersDrawer
                    {...drawerProps}
                    queryParams={AtoZFilterQueryParams}
                    filters={drawerFilters}
                    onModal={onModal}
                    collectionFilterMessages={alphabetFilterMessages}
                    amountMessages={{ [AtoZFilterEnum.AToZType]: showTypeFilterMessages }}
                    totalHits={totalHits}
                    closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                    modal={
                        <FilterModal
                            {...modalProps}
                            filter={selectedFilter}
                            filterMessages={alphabetFilterMessages}
                            queryParams={AtoZFilterQueryParams}
                            closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                        />
                    }
                />
            )}
        </>
    );
};

export default ProgrammeAlphabetFilters;
